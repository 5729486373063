.comparator {
  display: flex;
  flex-direction: column;
  .searchFields {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    .searchField {
      width: 50%; } }
  .compareContainer {
    display: flex; } }
