body {
  font-family: Roboto; }

button {
  margin-right: 5px;
  border-radius: 5px;
  background-color: #136EB3;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out,transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:disabled {
    background: #777;
    cursor: not-allowed; } }
