.sneakerDetails {
  width: 100%;
  .sneakerImage {
    height: 200px;
    img {
      max-height: 100%; } }
  .sneakerDataField {
    display: flex;
    flex-direction: row;
    height: 70px;
    .sneakerLabel {
      width: 125px; }
    .sneakerField {
      width: 200px; } } }
