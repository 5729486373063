.dropdown {
  position: absolute;
  overflow: auto;
  height: 400px;
  box-shadow: 0px 0px 4px 1px rgba(51,51,51,1);
  background: #fff; }
.sneakerContainer {
  display: flex;
  cursor: pointer;
  height: 150px;
  width: 400px;
  border-bottom: 1px solid #000;
  &:hover {
    opacity: 0.8; }
  .sneakerThumbnail {
    position: relative;
    max-width: 100px;
    width: 100px;
    margin: 0 20px;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: auto;
      min-height: 0;
      vertical-align: middle; } }
  .sneakerInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; } }
