.header {
  display: flex;
  box-shadow: 0 3px 5px -5px #333;
  margin-bottom: 20px;
  .brand {
    display: flex;
    padding-right: 20px;
    box-shadow: 5px 0px 10px -12px #333;
    div {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold; } }
  .menu {
    display: flex;
    align-items: center;
    .menuItem {
      padding: 0 10px;
      box-shadow: 5px 0px 10px -12px #333;
      text-decoration: inherit;
      color: inherit;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      &:hover {
        opacity: 0.8;
        cursor: pointer; } } } }
